.print_only {
  visibility:hidden;
  display: none;
}
.print_only_inline {
  visibility:hidden;
  display: none;
}
@media print {
    .print_only {
      visibility:visible;
      display: block;
    }
    .print_only_inline {
      display: inline-block;
    }
    .print_no {
      display: none;
      visibility:hidden;
    }
    .basic__top {
      padding-top: 0px ;
    }
    .hp {
      min-height: auto;
    }
    .menu__box {
    float: none;
    width: 100%;
    padding-right: 0px;
    max-width: auto;
    }
    .menu__box h1 {
      font-size: 1.7rem;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .menu__box h1 span {
      color: $c-black;
    }
    .menu__wrap {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .group__title {
      font-size: 1.3rem;
    }
    .menu__items td {
      font-size: 1rem;
      padding: 2px 10px 2px 0px !important;
    }
    .menu__items {
      margin: 0px 0px 20px 0px;
    }

}

