/* VARIABLES */

/* FONTS */
$f45_light: "f45_light", helvetica, arial, sans-serif;
$f45_light_i: "f45_light_i", helvetica, arial, sans-serif;
$f55_regular: "f55_regular", helvetica, arial, sans-serif;
$f55_regular_i: "f55_regular_i", helvetica, arial, sans-serif;
$f65_l_bold: "f65_l_bold", helvetica, arial, sans-serif;
$f65_l_bold_i: "f65_l_bold_i", helvetica, arial, sans-serif;


/* COLORS */
$c-white: rgba(255,255,255,1); //#FFFFFF
$c-pink: rgba(253,104,220,1); //#fd68dc
$c-yellow: rgba(206,222,0,1); //#cede00
$c-black: rgba(0,0,0,1); //#000000

$c-grey: rgba(83,87,85,1); //#53575f
$c-grey-light: rgba(90,90,90,1); //#5a5a5a
$c-grey-dark: rgba(60,60,60,1); //#3c3c3c


/* border-radius */
$border-radius: 4px;

/* paddings */
$p-basic: 10px;

/* widths */
$w-max: 1500px;



/* widths */
//basic height for button, and inputs
$h-basic: 40px;

/* annimation */
$ct-ease-swift: cubic-bezier(0, 0.1, 0.3, 1);
$ct-ease-ease-out: cubic-bezier(0, 0, 0.3, 1);
$ct-ease-ease-in-out: cubic-bezier(0.1, 0, 0.2, 1);
