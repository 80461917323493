.menu {
  padding-bottom: 0px;
}
.menu__wrap {
  background-color: $c-white;
  padding-bottom: 100px;
  padding-top: 35px;
  @media screen and (max-width: 650px) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.menu__box {
  float: left;
  width: calc(100% - 376px);
  padding-right: 6 * $p-basic;
  max-width: 940px;
  @media screen and (max-width: 990px) {
    width: calc(100% - 250px);
      padding-right: 3 * $p-basic;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 35px;
  }
}

.menu__box h1 {
  text-transform: uppercase;
  font-family: $f55_regular;
  font-weight: normal;
  font-size: 2.89rem;
  padding: 0px 0px 20px 0px;
  margin: 0;
  @media screen and (max-width: 650px) {
    font-size: 1.89rem;
  }
}

.menu__box__toggleWeek {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}
.menu__box__toggleWeek li {
  margin: 0;
  padding: 0px 0px 20px 32px;
  display: block;
  font-family: $f45_light;
  font-weight: normal;
  font-size: 1.56rem;
  background-size: 21px 21px;
  background-repeat: no-repeat;
  background-position: left top 8px;
  background-image: url(./images/radioOff.png);
  @media screen and (max-width: 650px) {
    font-size: 1.3rem;
    background-position: left top 6px;
  }
}
.menu__box__toggleWeek li.active {
  font-family: $f55_regular;
  background-image: url(./images/radioOn.png);
}

.menu__box__toggleWeek li a {
  color: $c-grey;
  text-decoration: none;
  @include trans(0.3s, color);
  &:hover {
    color: $c-pink;
    @include trans(0.3s, color);
  }
}
.menu__aside {
  float: right;
  width: 376px;
  padding-left: 12px;
  padding-right: 12px;
  @media screen and (max-width: 990px) {
      width: 250px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;;
  }
}

.menu__aside {
  display: flex;
  flex-wrap: wrap;
  .hpBox__item {
    width: 100%;
    color: $c-black;
    margin-right: 0px;
    &::before, &::after {
      border-color: $c-black;
    }
  }
  .hpBox__item h2, .hpBox__item__quote {
    &::before {
           background-color: $c-black;
     }
  }
}

.menu__mainToggle {
  padding: 0px 0px 20px 0px;
}

.menu__mainToggle ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  width: auto;
}
.menu__mainToggle li {
  float: left;
  margin-right: 30px;
  position: relative;
  &::before {
    content: "|";
    position: absolute;
    right: -30px;
    top: 0px;
    width: 30px;
    height: 100%;
    color: $c-grey;
    z-index: 1;
    text-align: center;
  }
  &:last-child {
    &::before {
      display: none;
      width: 0px;
    }
  }
}
.menu__mainToggle a {
  font-family: $f65_l_bold;
  color: $c-grey;
}
.menu__mainToggle li.active a {
  color: $c-pink;
}

.group__header {
  border-bottom: 1px solid $c-grey;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.group__title {
  margin-bottom: 0px;
  font-size: 1.875rem;
  text-transform: uppercase;
  @media screen and (max-width: 650px) {
    font-size: 1.3rem;
  }
}
.menu__items {
  padding: 0;
  width: 100%;
  margin: 0px 0px 40px 0px;
}
.menu__items td {
  font-family: $f45_light;
  color: $c-grey;
  font-size: 1.4rem;
  vertical-align: top;
  @media screen and (max-width: 650px) {
    font-size: 1rem;
  }
}
.menu__items__quantinty {
  width: 70px;
  padding: 5px 10px 5px 0px;
  @media screen and (max-width: 650px) {
    width: 50px;
  }
}
.menu__items__name {
  padding: 5px 10px 5px 0px;
}
.menu__items__price {
  width: 100px;
  text-align: right;
  padding: 5px 10px 5px 0px;
  @media screen and (max-width: 650px) {
    width: 60px;
  }
}

.menu__print {
  position: relative;
}
.menu__print  button{
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  background-color: transparent;
}

.menu__print__icon {
  width: 50px;
  height: 50px;

}
