html,
body {
    padding: 0;
    margin: 0;
    font-family: $f45_light;
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;
}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}


.hidden {
  display: none;
}


strong {
    font-family: $f65_l_bold;
    font-weight: 600;
}
.basic__top {
   background-image: url(./images/hp_back.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 255px;
  position: relative;
  @media  screen and (max-width: 805px) {
       padding-top: 200px;
      }
      @media  screen and (max-width: 551px) {
       padding-top: 200px;
      }
      @media  screen and (max-width: 451px) {
       padding-top: 200px;
      }
}

/* KONTAIERY */

.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    @media only screen and (min-width: 640px) {
        padding-right: 16px;
        padding-left: 16px;
    }
    @media only screen and (min-width: 768px) {
        padding-right: 24px;
        padding-left: 24px;
    }
    @media only screen and (min-width: 990px) {
        padding-right: 36px;
        padding-left: 36px;
    }
    @media only screen and (min-width: 1024px) {
        padding-right: 48px;
        padding-left: 48px;
    }
    @media only screen and (min-width: 1366px) {
        max-width: 1600px;
        padding-right: 48px;
        padding-left: 48px;
    }
}

.container__noPadd {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    @media only screen and (min-width: 1366px) {
        max-width: 1366px;
        padding-right: 48px;
        padding-left: 48px;
    }
}

.container__basicPad {
    padding-top: 3 * $p-basic;
    padding-bottom: 5 * $p-basic;
    @media only screen and (max-width: 768px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 4 * $p-basic;
    }
    @media only screen and (max-width: 640px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 2 * $p-basic;
    }
}

.link-pink {
  color: $c-pink;
  font-family: $f55_regular;
  a {
    color: $c-pink;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* CLEARFIX */

.cf:before,
.cf:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

.mb-6 {
    margin-bottom: 6 * $p-basic;
}

.mb-4 {
    margin-bottom: 4 * $p-basic;
}

.mb-3 {
    margin-bottom: 3 * $p-basic;
}

.mb-2 {
    margin-bottom: 2 * $p-basic;
}

.mt-6 {
    margin-top: 6 * $p-basic;
}

.mt-4 {
    margin-top: 4 * $p-basic;
}

.mt-3 {
    margin-top: 3 * $p-basic;
}

.mt-2 {
    margin-top: 2 * $p-basic;
}

.ta-center {
    text-align: center;
}



/* MODAL

.modal {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.modal__body {
    width: 100%;
    max-width: 700px;
    margin: (3 * $p-basic) auto (3 * $p-basic) auto;
    padding: 36px 30px 30px 30px;
    border-radius: $border-radius;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    @media screen and (max-width: 560px) {
        padding: 12px 10px 10px 10px;
    }
    position: relative;
}

.modal__close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px;
    height: 30px;
    //background-image: url(../../images/icon_close.svg);
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    border: none;
    outline: none;
    transform: rotate(0Deg);
    @include trans();
    &:hover {
        @include trans();
        transform: rotate(180Deg);
    }
    @media screen and (max-width: 560px) {
        right: 10px;
        top: 10px;
    }
}

.modal__form {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.modal__hidden {
    display: none;
}

.modal__content h3 {

    text-transform: uppercase;
    margin-bottom: 2 * $p-basic;
}

.modal__content h2 {
    text-transform: uppercase;
    margin-bottom: 4.5 * $p-basic;

}

.form__left_1_2 {
    display: block;
    width: calc((100% - 20px) / 2);
    margin: 0px 20px 20px 0px;
    padding: 0px 0px 0px 0px;
    @media screen and (max-width: 560px) {
        width: 100%;
        margin: 0px 0px 20px 0px;
    }
}

.form__right_1_2 {
    display: block;
    width: calc((100% - 20px) / 2);
    margin: 0px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
    @media screen and (max-width: 560px) {
        width: 100%;
        margin: 0px 0px 20px 0px;
    }
}

.form__full {
    display: block;
    width: 100%;
    margin: 0px 0px 20px 0px;
}

.form__submit {
    display: block;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;

    border-radius: 0px;
    height: 50px;

    text-transform: uppercase;
    line-height: normal;
    @include trans();
    &:hover {
        @include trans();
    }
}

.modal__form input[type="text"],
.modal__form input[type="email"],
.modal__form input[type="tel"] {
    border-radius: $border-radius;
    height: 56px;
    padding: 0px 14px;
    box-sizing: border-box;
    width: 100%;
}

.modal__textarea {
    border-radius: $border-radius;
    height: 120px;
    padding: 14px 14px;
    box-sizing: border-box;
    width: 100%;
}
 */
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
   color: rgba($c-grey, 0.40);
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba($c-grey, 0.40);
}

:-ms-input-placeholder {
    /* IE 10+ */
   color: rgba($c-grey, 0.40);
}

:-moz-placeholder {
    /* Firefox 18- */
    color: rgba($c-grey, 0.40);
}

.map {
    min-height: 80vh;
    @media screen and (max-width: 768px) {
         min-height: 80vh;
    }

    img {
      display: block;
    }

}
.map > div {
  width: 100% !important;
  height: 100% !important;
}

/* MODAl NEW */
$modalBP: 650px;

.modal {
  display: none; /* Skryto jako výchozí */
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: $c-yellow;
  margin: 15% auto;
  padding: 0px;
  border: 0px solid #888;
  width: 100%;
  max-width: 850px;
  text-align: left;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: $modalBP){
    flex-wrap: nowrap;
  }
}

.modal-txt {
  padding: 15px;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 100%;
  @media screen and (min-width: $modalBP){
    width: 50%;
    padding: 90px 60px 60px 60px ;
  }
}

.modal-txt h2 {
  font-family: $f65_l_bold;
  text-align: left;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 20px;
  margin-top: 0px;
  font-size: 26px;
    line-height: 31px;
  @media screen and (min-width: $modalBP){
    margin-bottom: 0px;
    font-size: 33px;
    line-height: 36px;
    margin-bottom: 10px;
  }
}

.modal-txt p {
  font-family: $f65_l_bold;
  text-align: left;
  margin-bottom: 20px;
}


.modal-img {
    position: relative;
    width: 100%;
    @media screen and (min-width: $modalBP){
      width: 50%;
    }
}

.modal-img  img {
  height: auto;
  @media screen and (min-width: $modalBP){
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right bottom;
  }

}

.charity_big {
  display: none;
  @media screen and (min-width: $modalBP){
    display: block;
  }
}
.charity_mobile {
  display: block;
  @media screen and (min-width: $modalBP){
    display: none;
  }
}


.modal-content .close {
  color: #FFFFFF;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
  z-index: 10;
}

.modal-content .close:hover,
.modal-content .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content .button {
  font-family: $f65_l_bold;
  padding: 14px 20px 12px 20px;
  font-size: 16px;
  text-decoration: none;
  color: $c-yellow;
  background-color: #ffffff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  display: inline-block;
}

.modal-content .button:hover {
  background-color: $c-pink;
}



/* MODAL STOP */
