
.contact h1 {
  text-transform: uppercase;
}

.contact__box {
  display: flex;
  @media screen and (max-width: 650px) {
     flex-wrap: wrap;
  }
}

.contact__boxItem {
  width: calc((100% - (2 * 70px))/3);
  margin-right: 70px;
  &:last-child {
    margin-right: 0px;
  }
  @media screen and (max-width: 650px) {
     width: 100%;
     margin-right: 0px;
     margin-bottom: 40px;
     &:last-child {
       //margin-bottom: 0px;
     }
  }
}

.contact__boxItem h3 {
  text-transform: uppercase;
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #000000;
}
