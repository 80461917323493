/* MIXINS */

/* TYPO
* Základní velikost písma v dokumentu: %
* Rozměry vycházející z velikosti písma dokumentu: rem
* Rozměry vycházející z velikosti písma rodiče: em
* Media Queries: em
* Výška řádku: číslem bez jednotky
* Rámečky, dekorace: px
* Typografie podle velikosti okna: vw
*/


@mixin shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
	-webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
	-moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
	box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}
@mixin trans($t: 0.5s, $type: all) {
	-webkit-transition: $type $t ease;
	-ms-transition: $type $t ease;
	transition: $type $t ease;
}
