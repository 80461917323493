.galleries__wrap h1 {
  font-family: $f55_regular;
  font-weight: normal;
  font-size: 2.1875rem;
  margin-bottom: 60px;
  text-transform: uppercase;
}

.galleries__header {
    color: $c-grey;
    color: $c-grey;
    font-family: $f45_light;
    font-weight: normal;
    font-size: 1rem;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid $c-grey;
}

.galleries__header h2 {
    color: $c-grey;
    text-transform: uppercase;
    font-family: $f45_light;
    font-weight: normal;
    font-size: 1.875rem;
    margin-bottom: 0px;
}
.galleries .item {
  margin-bottom: 25px;
}

.gallery__holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

}

.gallery__item {
    margin-bottom: 26px;
    display: block;
    @media screen and (min-width: 951px) {
        width: calc((100% - (3 * 26px))/4);
        margin-right: 26px;
        &:nth-child(4n) {
            margin-right: 0px;
        }
    }
    @media screen and (max-width: 950px) and (min-width: 751px) {
        width: calc((100% - (2 * 15px))/3);
        margin-right: 15px;
        &:nth-child(3n) {
            margin-right: 0px;
        }
    }
    @media screen and (max-width: 750px) {
        width: calc((100% - (1 * 15px))/2);
        margin-right: 15px;
        margin-bottom: 15px;
        &:nth-child(even) {
            margin-right: 0px;
        }
    }
}

.gallery__item img {
  width: 100%;
  height: auto;
}
.sl-overlay {
  background: #3c3c3c !important;
  opacity: 0.99 !important;
}
.sl-prev {
  width: 74px !important;
  height: 74px !important;
  overflow: hidden;
  text-indent: 400px;
  background-image: url(./images/arrow_left.svg) !important;
  background-size: 74px 74px !important;
  background-repeat: no-repeat  !important;
}
.sl-next {
  width: 74px !important;
  height: 74px !important;
  overflow: hidden;
  text-indent: 400px;
  background-image: url(./images/arrow_right.svg) !important;
  background-size: 74px 74px !important;
  background-repeat: no-repeat  !important;
}
.sl-counter {
  color: $c-white !important;
}
.sl-close {
  width: 83px !important;
  height: 83px !important;
  overflow: hidden;
  text-indent: 400px;
  background-image: url(./images/hamburger-x.svg) !important;
  background-size: 83px 83px !important;
  background-repeat: no-repeat  !important;
}
