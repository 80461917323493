/* FORMS */
.hp {
  min-height: 100vh;
  padding-bottom: 100px;
  @media screen and (max-width: 560px) {
      padding-bottom: 20px;
  }
}

.hpBox {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  @media screen and (max-width: 790px) {
      flex-wrap: wrap;
  }
  @media screen and (max-width: 650px) {
      align-items: flex-start;
  }
}

.hpBox__item {

    color: $c-white;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 1px solid $c-white;
      left: 0;
      top: 0;
      transform: rotate(-1deg);
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 1px solid $c-white;
      left: 0;
      top: 0;
      transform: rotate(1deg);
    }

    @media screen and (min-width: 791px) {
        width: calc((100% - (3 * 25px)) / 4);
        margin-right: 25px;

        padding: 38px;
        &:last-child {
            margin-right: 0px;
        }
    }

    @media screen and (max-width: 990px) {
        padding: 20px;
    }

    @media screen and (max-width: 790px) {
        width: calc((100% - (1 * 25px)) / 2);
        margin-right: 25px;
        padding: 38px;
        &:nth-child(even) {
            margin-right: 0px;
        }
         &:last-child {
            margin-right: 0px;
        }
        order: 2;
    }

    @media screen and (max-width: 650px) {
         padding: 20px;
    }

    @media screen and (max-width: 550px) {
        width: 100%;
        padding: 20px;
        margin-right: 0px;
    }

}

.hpBox__item:hover {
    &::before {
        animation: hpBoxItemRot1 0.5s ease-in-out  0s 1 forwards;
    }
    &::after {
        animation: hpBoxItemRot2 0.5s ease-in-out  0s 1 forwards;
    }
}

.hpBoxNew {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
  max-width: 840px;
}

.hpBox__itemSub {
  @extend .hpBox__item;
  margin: 0 auto;
  max-width: 460px;
  width: 100%;
  margin-right: auto !important;
  @media screen and (min-width: 650px) {
    width: 50%;
  }
}
.hpBox__itemSub__ball {
  width: 100%;
  max-width: 120px;
  height: auto;
  display: block;
  margin: 0 auto;
}
.hpBox__itemSub__ul {
  list-style-type: none;
  padding: 0;
  & li {
    margin-bottom: 30px;
    border-bottom: 0px solid white;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 2px;
      bottom: -12px;
      left: 50%;
      margin-left: -5px;
      background-color: white;
    }
    &:last-child {
      margin-top: -15px;
    }
  }
  & span {
    color: red;
    font-size: 2rem;
    position: relative;
    top: 5px;
  }
}


.hpBox__itemDouble {

    color: #565a5e;
    background-color: #cede00;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    font-family: $f65_l_bold;
    font-size: 1.25rem;
    letter-spacing: 0.1rem;
    margin: 0px auto;
    @media screen and (min-width: 791px) {
        width: calc(50%);
        padding: 38px;

    }

    @media screen and (max-width: 990px) {
        padding: 20px;
    }

    @media screen and (max-width: 790px) {
        width: calc(100%);
        margin-right: 0px;
        padding: 38px;
        order: 1;
    }

    @media screen and (max-width: 650px) {
         padding: 20px;
    }

    @media screen and (max-width: 550px) {
        width: 100%;
        padding: 20px;
        margin-right: 0px;
    }

}



@keyframes hpBoxItemRot1 {
  from {
   transform: rotate(1deg);
  }

  50% {
    transform: rotate(-1deg);
  }

  to {
    transform: rotate(0.95deg);
  }
}
@keyframes hpBoxItemRot2 {
  from {
   transform: rotate(-1deg);
  }

  50% {
    transform: rotate(1deg);
  }

  to {
    transform: rotate(-0.95deg);
  }
}

.hpBox__item p {
  margin-bottom: 23px;
}
.hpBox__item h2, .hpBox__item__quote {
  text-transform: uppercase;
  color: $c-yellow;
  font-family: $f65_l_bold;
  font-weight: bold;
  position: relative;
  margin-bottom: 35px ;
  margin-top: 0px;
  z-index: 100;
  &::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 2px;
      bottom: -17px;
      left: 50%;
      margin-left: -5px;
      background-color: $c-white;
  }
}



.hpBox__item__quote {
  margin-top: 23px;
   margin-bottom: 35px !important ;
  text-transform: none;
}

.hpBox__item a {
  position: relative;
  z-index: 100;
}

.hpBox__item__links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  width: auto;
  display: flex;
  justify-content: center;

}

.hpBox__item__links li {
  text-align: center;
  margin-right: 30px;
  &:last-child {
    margin-right: 0px;
  }
}
.hpBox__item__links li a {
  text-align: center;
  color: $c-pink;

    &:hover {
      .hpBox__item__links__book, .hpBox__item__links__phone, .hpBox__item__links__email {
      @include trans();
      transform: rotate(-20deg);
      }
    }
}
.hpBox__item__links__book {
  width: 40px;
  height: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 100;
  @include trans();
  transform: rotate(0deg);
}
.hpBox__item__links__phone {
  width: 42px;
  height: 42px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 100;
  @include trans();
  transform: rotate(0deg);
}
.hpBox__item__links__email {
   width: 49px;
  height: 49px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 100;
  @include trans();
  transform: rotate(0deg);
}

.hiddenHpBox {
     @media screen and (max-width: 650px) {
       .hpBox__item__mobileHide {
          display: none;
       }
       &.hpBox__item h2 {
         margin-bottom: 0px;
         &::before {
           display: none;
         }
       }
    }
}

.showHpBox {
     @media screen and (max-width: 650px) {
        .hpBox__item__mobileHide {
          display: block;
       }
       &.hpBox__item h2 {
         margin-bottom: 35px;
         &::before {
           display: block;
         }
       }
    }
}

.gFamily {
  background-color: $c-yellow;
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 110px;
  }
}

.gFamily .container {
 display: grid;
 gap: 20px;
 grid-template-columns: 1fr;
  @media screen and (min-width: 650px) {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  @media screen and (min-width: 990px) {
    gap: 160px;
  }
}

.gFamily_txt {
  font-size: 1rem;
  @media screen and (min-width: 650px) {
    font-size: 1.3rem;
   }
}

.gFamily_txt_logos {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap;
   @media screen and (min-width: 650px) {
    margin-top: 45px;
    flex-wrap: nowrap;
   }
   @media screen and (min-width: 650px) and (max-width: 1206px) {
    flex-wrap: wrap;
    margin-top: 25px;
   }
}

.gFamily_txt_logos a{
  display: block;
  width: calc(50% - 10px);
   @media screen and (min-width: 450px) {
    width: auto;
   }
   @media screen and (min-width: 650px) and (max-width: 1206px) {
    width: calc(50% - 10px);
   }
}

.gFamily_txt_logos img {
  display: block;
  margin: 0 auto;
}

.gFamily_carousel {
  overflow: hidden;
  padding-bottom: 55px;
  @media screen and (min-width: 650px) {
    padding-bottom: 75px;
   }
}
.homepageCar {
  width: 100%;
}

.gFamily_carousel_img {
  display: block;
  width: 100%;
  height: auto;
}

.gFamily_btn {
  display: block;
 width: 48px;
 height: 48px;
 position: absolute;
 bottom: -55px;
 left: 60px;
 background-image: url(./images/carArrow.svg);
 background-size: 48px 48px;
 background-repeat: no-repeat;
 background-size: center center;
 cursor: pointer;
 border: none;
 transform: rotate(0deg);
 @include  trans();
 &:hover {
   transform: rotate(-30deg);
 }
 @media screen and (min-width: 650px) {
  bottom: -75px;
 }
}

.gFamily_btnNext {
  left: 0px;
  transform: rotate(180deg);
  &:hover {
    transform: rotate(210deg);
  }
}
