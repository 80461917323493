@font-face {
    font-family: 'f45_light';
    src: url('../fonts/frutiger_ce_45_light.woff2') format('woff2'),
         url('../fonts/frutiger_ce_45_light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'f46_light_i';
    src: url('../fonts/frutiger_ce_46_light_italic.woff2') format('woff2'),
         url('../fonts/frutiger_ce_46_light_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'f55_regular';
    src: url('../fonts/frutiger_ce_55_roman.woff2') format('woff2'),
         url('../fonts/frutiger_ce_55_roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'f56_regular_i';
    src: url('../fonts/frutiger_ce_56_italic.woff2') format('woff2'),
         url('../fonts/frutiger_ce_56_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'f65_l_bold';
    src: url('../fonts/frutiger_ce_65_bold.woff2') format('woff2'),
         url('../fonts/frutiger_ce_65_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'f65_l_bold_i';
    src: url('../fonts/frutiger_ce_66_bold_italic.woff2') format('woff2'),
         url('../fonts/frutiger_ce_66_bold_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
