/* HEADERS */
.header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
  padding-top: 5 * $p-basic;
  padding-bottom: 3 * $p-basic;

  @media  screen and (max-width: 551px) {

      padding-top: 3 * $p-basic;
  }
  @media  screen and (max-width: 451px) {

      padding-top: 2 * $p-basic;
  }
}
.header__wrapp {
  width: calc(100% - 90px);
   @media  screen and (max-width: 551px) {
       width: calc(100%);
  }
}
.header__logo {
 width: 100%;
  max-width: 450px;
  padding-right: 3 * $p-basic;

}

.header__logo__txt {
  position: relative;
  z-index: 100;
  color: $c-white;
   @media  screen and (max-width: 551px) {
     font-size: 0.9rem;
  }
}
.header__logo__txt a.map {
  color: $c-white;
  text-decoration: underline;
  @include trans(0.3s, color);
  &:hover {
    color: $c-pink;
    @include trans(0.3s, color);
  }
}


.header__logo img {
  width: 100%;
  max-width: 384px;

  display: block;
  margin-bottom: 2 * $p-basic;
  @media  screen and (max-width: 551px) {
     max-width: 230px;
  }
}


.header__logo__logo {
  position: relative;
  z-index: 202;
}

.header__logo .link-pink {
  color: $c-pink;
  font-family: $f55_regular;
  a {
    color: $c-pink;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.header__menu {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}


.header__tort {
  display: block;
  width: 380px;
  position: absolute;
  left: 50%;
  margin-left: 25px;
  top: 0px;
  font-size: 1.263rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  font-family: $f55_regular;
  text-align: center;
  color: $c-pink;
  text-decoration: none;
  z-index: 101;
  &:hover {
      text-decoration: underline;


  }
  @media  screen and (max-width: 800px) {
   width: 290px;
   z-index: 20;
  }
  @media  screen and (max-width: 805px) {
    width: 290px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 0%;
    top: 30px;
    margin-top: 2 * $p-basic;
      }
}
.header__tort__hand {
          animation: handAnim 1.3s ease-in-out 0s infinite forwards;
      }
@keyframes handAnim {
  0% {
      transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }

}

.header__tort img {
  width: 50px;
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;

}


.header__toggle {
  position: absolute;
  width: 70px;
  height: 44px;
  top: -10px;
  right: 0px;
  background-image: url(./images/hamburger.svg);
  background-size: 70px 44px;
  background-repeat: no-repeat ;
  border: none;
   z-index: 201;
   cursor: pointer;
}

.header__menu__wrap {
    background-color: $c-grey-dark;
    width: 100%;
    height: 100vh;
    top: -100vh;

    left: 0px;
    position: fixed;
    z-index: 200;
    @include trans(0.5s, top);
    padding-top: 14 * $p-basic;

     @media  screen and (max-width: 651px) {
        padding-top: 12 * $p-basic;
      }
      @media  screen and (max-width: 401px) {
        padding-top: 8 * $p-basic;
      }
}
.header__menu__wrap ul {
  margin: 0px 0px 0px 0px;
  list-style: none;
  width: 100%;
}

.header__menu__wrap ul li {
  margin: 0px;
  display: block;

  text-align: center;
}
.header__menu__wrap ul li a {
  padding: 5px 30px;
  display: block;
  text-align: center;
  color: $c-white;
  text-transform: uppercase;
  text-decoration: none;
  @include trans(0.3s, color);

  font-family: $f55_regular;
  font-size: 1.95rem;
  @media  screen and (max-width: 651px) {
       font-size: 1.5rem;
       padding: 5px 20px;
      }
   @media  screen and (max-height: 640px) {
       font-size: 1.3rem;
       padding: 5px 15px;
      }
  &:hover {
    color: $c-pink;

    @include trans(0.3s, color);
  }

}

.header__menu__ss__mob {
  margin-top: 40px !important;
}

.header__menu__ss__mob a {
  width: auto;
  display: inline-block !important;
   @include trans();
  transform: rotate(0deg);
    &:hover {

      @include trans();
      transform: rotate(-20deg);
    }
  &.social img {
    width: 40px;
    height: 40px;
    opacity: 0.8;
  }
}

.showMenu {
  .header__toggle {
    background-image: url(./images/hamburger-x.svg);

  }
  .header__menu__wrap {
      @include trans(0.5s, top);
      top:0px;


  }

}


.header__nav__social {
  @media  screen and (max-width: 700px) {
       display: none;
      }
  width: 110px;
  position: absolute;
  right: 55px;
  top: -7px;
  text-align: center;
  z-index: 1000;
  .social {
    display: inline-block;
     width: 40px;
    height: 40px;
    opacity: 0.8;
    cursor: pointer;
    position: relative;
    z-index: 100;
    @include trans();
    transform: rotate(0deg);
    &:hover {
      @include trans();
      transform: rotate(-20deg);
    }
  }
}
.header__nav__lang {
  width: 55px;
    top: 40px;
    right: 8px;
    position: absolute;
    text-align: center;
    z-index: 180;
    @media  screen and (max-width: 700px) {
       display: none;
      }
    a {
       @include trans(0.3s, color);
       color: $c-white;
        font-family: $f55_regular;
        font-size: 1.3rem;
        &:hover {
          color: $c-pink;

          @include trans(0.3s, color);
        }
    }
}
