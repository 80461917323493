.footer {
  background-color: $c-grey-light;
  padding-top: 50px;
  padding-bottom: 25px;
  color: rgba($c-white,0.5);
}
.footer a {
  text-decoration: underline;
  color: rgba($c-white,0.5);
  @include trans(0.3s, color);
  &:hover {
    @include trans(0.3s, color);
    color: rgba($c-white,1);
  }
}

.footer__wrap {
  display: flex;
  @media  screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
}

.footer__item1 {
   width: 30%;
  @media  screen and (max-width: 650px) {
    width: 100%;
    margin-bottom: 20px;
  }
}
.footer__item2 {
   width: 25%;
   padding-left: 25px;
   @media  screen and (max-width: 650px) {
    width: 100%;
    padding-left: 0px;
    margin-bottom: 20px;
   }
}
.footer__item3 {
   width: 45%;
   padding-left: 25px;
   @media  screen and (max-width: 650px) {
    width: 100%;
    padding-left: 0px;
   }
}

.footer__wrap__sup {
  color: rgba($c-white,0.5);
  margin-top: 20px;
  text-align: right;
}
